import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
//* Routes List start

const SplashScreen = lazy(() =>
  import('../../blocks/splashscreen/src/SplashScreen.web')
);

const LandingPage = lazy(() =>
  import('../../blocks/landingpage/src/LandingPage.web')
);

const CategoriesScreen = lazy(() =>
  import('../../blocks/categoriessubcategories/src/Categoriessubcategories.web')
)

const LoginAccount = lazy(() =>
  import('../../blocks/email-account-login/src/LoginAccount.web')
);

const SignUpAccount = lazy(() =>
  import('../../blocks/email-account-registration/src/SignUpAccount.web')
);

const TermsAndConditionPage = lazy(() =>
  import('../../blocks/TermsAndConditions3/src/TermsAndConditionPage.web')
);

const PlatformTerms = lazy(() =>
  import('../../blocks/TermsAndConditions3/src/TermsAndConditions3.web')
);

const ForgotPasswordScreen = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPasswordScreen.web')
);

const FooterMain = lazy(() =>
  import('../../components/src/FooterMain.web')
);

const NewPasswordScreen = lazy(() =>
  import('../../blocks/forgot-password/src/NewPasswordScreen.web')
);

const LearnMore = lazy(() =>
  import('../../blocks/email-account-registration/src/LearnMore.web'));

const ContactUs = lazy(() =>
  import('../../blocks/contactus/src/Contact_us.web')
);

const SellPage = lazy(() =>
  import('../../blocks/categoriessubcategories/src/Sell.web'))

const Chat = lazy(() => import("../../blocks/chat/src/Chat.web"));

const UserProfile = lazy(() =>
  import('../../blocks/CustomisableUserProfiles/src/UserProfile.web'))

const EditUserProfile = lazy(() =>
  import('../../blocks/CustomisableUserProfiles/src/EditUserProfile.web'))

const OrderDetail = lazy(() =>
  import("../../blocks/ordermanagement/src/OrderDetail.web"))

const Myproducts = lazy(() =>
  import('../../blocks/landingpage/src/MyProducts'))

const SelectProduct = lazy(() =>
  import('../../blocks/OrderCreation/src/SelectProduct.web'))

const BuyNowScreen = lazy(() =>
  import('../../blocks/OrderCreation/src/BuyNow.web'));

const PaymentScreen = lazy(() =>
  import('../../blocks/OrderCreation/src/Payment.web'));

const PurchaseSummary = lazy(() =>
  import('../../blocks/OrderCreation/src/PurchaseSummary.web'));

const HomePage = lazy(() =>
  import('../../blocks/landingpage/src/MyProducts'));

const LoginAccountStateDisable = lazy(() =>
  import('../../blocks/email-account-login/src/LoginAccountStateDisable.web'));

const ShopperProfile = lazy(() => import("../../blocks/ordermanagement/src/ShopperProfile.web"));

const ManageOrder = lazy(() => import("../../blocks/ordermanagement/src/ManageOrder.web"))

const OrderHistory = lazy(() =>
  import('../../blocks/OrderCreation/src/OrderHistory.web'));

const VendorUserProfile = lazy(() =>
  import('../../blocks/CustomisableUserProfiles/src/VendorUserProfile.web'))

const OrderTrack = lazy(() =>
  import('../../blocks/OrderCreation/src/OrderTrack.web'));

const Notifications = lazy(() =>
  import('../../blocks/notifications/src/Notifications.web'));

const Settings = lazy(() =>
  import('../../blocks/CustomisableUserProfiles/src/Settings.web'))

const NewProduct = lazy(() => import("../../blocks/InventoryManagement2/src/ProductInfo.web"));

const Productselection = lazy(() =>
  import('../../blocks/InventoryManagement2/src/productSelection.web'));

const EditProductInfo = lazy(() =>
  import('../../blocks/InventoryManagement2/src/EditProductInfo.web'));

const HomeMadeOperations = lazy(() =>
  import('../../blocks/InventoryManagement2/src/HomeMadeOperations'));

const CottageFoodOperations = lazy(() =>
  import('../../blocks/InventoryManagement2/src/CottageFoodOperations'));

const OrderCancel = lazy(() =>
  import('../../blocks/OrderCreation/src/OrderCancel.web'));

const ProductVendorProfile = lazy(() =>
  import('../../blocks/OrderCreation/src/ProductVenderProfile.web'));

const LoadingScreen = lazy(() =>
  import('../../components/src/LoadingScreen'));

const ShoppingCartPage = lazy(() =>
  import('../../blocks/shoppingcart/src/ShoppingCartPage.web'));

const ShoppingCartPackages = lazy(() =>
  import('../../blocks/shoppingcart/src/ShoppingCartPackages.web'));

const ShoppingCartPurchaseSummary = lazy(() =>
  import('../../blocks/shoppingcart/src/ShoppingCartPurchaseSummary.web'));

const ShoppingCartPayment = lazy(() =>
  import('../../blocks/shoppingcart/src/ShoppingCartPayment.web'));


const FavouritePage = lazy(() =>
  import('../../blocks/favourites/src/Favourites.web'));
const HelpCentre = lazy(() =>
  import("../../blocks/helpcentre/src/HelpCentre.web")
)

const Subscription = lazy(() =>
  import('../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions.web'));

const VendorFaqList = lazy(() =>
  import('../../blocks/OrderCreation/src/VendorFaqList.web'));

const PrivacyPolicy = lazy(() =>
  import("../../blocks/TermsAndConditions3/src/PrivacyPolicy.web"));

const OTPVerification = lazy(() =>
  import("../../blocks/forgot-password/src/OTPVerification.web"));

const MeetOurFounder = lazy(() =>
  import('../../blocks/landingpage/src/MeetOurFounder.web'));

const ParticipatingStates = lazy(() =>
  import('../../blocks/landingpage/src/ParticipatingStates.web'));

const Pricing = lazy(() =>
  import('../../blocks/landingpage/src/Pricing.web'));

// /* Private Routes start /
import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';

// /* Private Roues End /

function WebRoutes(rootProps: any) {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props => <SplashScreen {...rootProps} {...props} />}
          />
          <Route
            path="/landingpage"
            exact
            render={props => <LandingPage {...rootProps} {...props} />}
          />

          <Route
            path="/login"
            exact
            render={props => <LoginAccount {...rootProps} {...props} />}
          />

          <Route
            path="/signup"
            exact
            render={props => <SignUpAccount {...rootProps} {...props} />}
          />

          <PrivateRoute
            path="/favourites"
            exact
            render={props => <FavouritePage {...rootProps} {...props} />}
            isAlowedBeforeLogin={false}
            history={undefined}
          />

          <PrivateRoute
            path="/homepage"
            exact
            render={props => <HomePage {...rootProps} {...props} />}
            isAlowedBeforeLogin={false}
            history={undefined}
          />
          <PrivateRoute
            path="/statedisable"
            exact
            render={props => <LoginAccountStateDisable {...rootProps} {...props} />}
            isAlowedBeforeLogin={true}
            history={undefined}
          />
          <PrivateRoute
            path="/shopper-terms-and-conditions"
            exact
            render={props => <TermsAndConditionPage {...rootProps} {...props} />}
            isAlowedBeforeLogin={true}
            history={undefined}
          />

          <PrivateRoute
            path="/business-terms-and-conditions"
            exact
            render={props => <TermsAndConditionPage {...rootProps} {...props} />}
            isAlowedBeforeLogin={true}
            history={undefined}
          />

          <Route
            path="/platform-terms-of-use"
            exact
            render={props => <PlatformTerms {...rootProps} {...props} />}
          />
          <Route
            path="/footer"
            exact
            render={props => <FooterMain {...rootProps} {...props} />}
          />
          <Route
            path="/forgotPassword"
            exact
            render={props => <ForgotPasswordScreen {...rootProps} {...props} />}
          />
          <Route
            path="/otpverification"
            exact
            render={props => <OTPVerification {...rootProps} {...props} />}
          />
          <Route
            path="/newpassword"
            exact
            render={props => <NewPasswordScreen {...rootProps} {...props} />}
          />
          <Route
            path="/categories"
            exact
            render={props => <CategoriesScreen {...rootProps} {...props} />}
          />
          <Route
            path="/learnmore"
            exact
            render={props => <LearnMore {...rootProps} {...props} />}
          />
          <Route
            path="/shoppers"
            exact
            render={props => <LearnMore {...rootProps} {...props} />}
          />
          <Route
            path="/small-businesses"
            exact
            render={props => <LearnMore {...rootProps} {...props} />}
          />

          <PrivateRoute
            path="/sell"
            exact
            render={props => <SellPage {...rootProps} {...props} />}
            isAlowedBeforeLogin={true}
            history={undefined}
          />

          <PrivateRoute
            path="/contactus"
            exact
            render={props => <ContactUs {...rootProps} {...props} />}
            isAlowedBeforeLogin={true}
            history={undefined}
          />

          <PrivateRoute
            path="/settings"
            exact
            render={props => <Settings {...rootProps} {...props} />}
            isAlowedBeforeLogin={false}
            history={undefined}
          />

          <PrivateRoute
            path="/userProfile"
            exact
            render={props => <UserProfile {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/editUserProfile"
            exact
            render={props => <EditUserProfile {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/chat"
            exact
            render={props => <Chat {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/selectedProduct"
            exact
            render={props => <SelectProduct {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={true}
          />
          <PrivateRoute
            path="/buyNow"
            exact
            render={props => <BuyNowScreen {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/paymentMethod"
            exact
            render={props => <PaymentScreen {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/purchaseSummary"
            exact
            render={props => <PurchaseSummary {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/shopper_profile"
            exact
            render={props => <ShopperProfile {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />

          <PrivateRoute
            path="/shopper_profile/:accountId"
            exact
            render={props => <ShopperProfile {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />

          <PrivateRoute
            path="/manage_order"
            exact
            render={props => <ManageOrder {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/orderHistory"
            exact
            render={props => <OrderHistory {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/orderTrack"
            exact
            render={props => <OrderTrack {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/notifications"
            exact
            render={props => <Notifications {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />

          <PrivateRoute
            path="/vendorprofile"
            exact
            render={props => <VendorUserProfile {...rootProps} {...props} />} history={undefined}
            isAlowedBeforeLogin={false}
          />

          <PrivateRoute
            path={"/new-product"}
            exact
            render={props => <NewProduct {...rootProps} {...props} />}
            isAlowedBeforeLogin={false}
            history={undefined}
          />
          <Route
            path="/productselection"
            exact
            render={props => <Productselection {...rootProps} {...props} />}
          />

          <Route
            path="/processingorder"
            exact
            render={props => <LoadingScreen {...rootProps} {...props} />}
          />

          <PrivateRoute
            path={"/editproductinfo"}
            exact
            render={props => <EditProductInfo {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/order-detail/:orderId"
            exact
            render={props => <OrderDetail {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/orderCancel"
            exact
            render={props => <OrderCancel {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/productVenderProfile"
            exact
            render={props => <ProductVendorProfile {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={true}
          />

          <PrivateRoute
            path="/shoppingcartpage"
            exact
            render={props => <ShoppingCartPage {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/shoppingCartPackages"
            exact
            render={props => <ShoppingCartPackages {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />
          <PrivateRoute
            path="/cartpurchaseSummary"
            exact
            render={props => <ShoppingCartPurchaseSummary {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />

          <PrivateRoute
            path="/shoppingpayment"
            exact
            render={props => <ShoppingCartPayment {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={false}
          />

          <Route
            path="/help-center"
            exact
            render={props => <HelpCentre {...rootProps} {...props} />}
          />
          
          <Route
            path="/founder"
            exact
            render={props => <MeetOurFounder {...rootProps} {...props} />}
          />

          <Route
            path="/participating-states"
            exact
            render={props => <ParticipatingStates {...rootProps} {...props} />}
          />

          <Route
            path="/pricing"
            exact
            render={props => <Pricing {...rootProps} {...props} />}
          />

          <PrivateRoute
            path="/subscription"
            exact
            render={props => <Subscription {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={true}
          />

          <Route
            path="/productVenderProfile/vendorFaq/:id"
            exact
            render={props => <VendorFaqList {...rootProps} {...props} />}
          />
          <Route
            path="/homemade-food-operations-products"
            exact
            render={props => <HomeMadeOperations {...rootProps} {...props} />}
          />

          <Route
            path="/cottage-food-operations-products"
            exact
            render={props => <CottageFoodOperations {...rootProps} {...props} />}
          />

          <PrivateRoute
            path="/privacy-policy"
            exact
            render={props => <PrivacyPolicy {...rootProps} {...props} />}
            history={undefined}
            isAlowedBeforeLogin={true}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

//@ts-ignore
export default withRouter(WebRoutes);